<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { getDefinition, addDefinition, patchDefinition } from "@/services/api/measure.api";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { getEcmFunctions } from "@/services/api/masterdata.api";
import { getNlSfbCodes, getCategories, getKinds, getConditionTypes } from "@/services/api/measure.api";
import _ from "lodash";
import { emptyGuid } from "@/composables/miscellaneous.js";
import router from "@/router/index";
import DateInput from "@/components/general/DateInput.vue";

const { formatDateUtcDT } = useDateTimeFormatter();

setInteractionMode("eager");

const props = defineProps({
  id: {
    type: String,
    default: undefined,
  },
  addModus: {
    type: Boolean,
    default: false,
  },
});

const measure = ref({
  id: undefined,
  buildingFunction: { id: undefined },
  category: { id: undefined },
  nlSfbCode: { id: undefined },
  kindKey: undefined,
  categoryKey: undefined,
  variants: [],
});
const nlSfbSubCategory = ref(undefined);
const lastModDescr = ref(undefined);

const editMode = ref(true);

const dataMustBeRetrieved = ref(false);

const buildingFunctions = ref([]);
const nlSfbCodes = ref([]);
const nlSfbCodesFiltered = ref([]);
const nlSfbSubCategories = ref([]);
const categories = ref([]);
const kinds = ref([]);
const conditionTypes = ref([]);
const isSaving = ref(false);

const buildingFunctionsLoaded = ref(false);
const nlSfbCodesLoaded = ref(false);
const categoriesLoaded = ref(false);
const kindsLoaded = ref(false);
const conditionTypesLoaded = ref(false);

const variantTypes = ref(["nvt", "a", "b", "c", "d", "e", "f", "g", "1", "2", "3", "4", "5", "6", "7", "8", "9"]);

const masterDataLoaded = computed(() => buildingFunctionsLoaded.value && nlSfbCodesLoaded.value && categoriesLoaded.value && kindsLoaded.value && conditionTypesLoaded.value);
const modDateFormatted = computed(() => formatDateUtcDT(measure.value.modDat));
const allowEdit = computed(() => props.addModus || editMode.value);
const disableSave = computed(() => (!props.addModus && !measure.value.id) || isSaving.value);

watch(
  () => props.id,
  () => {
    console.log("Measure, watch ", props.id);
    dataMustBeRetrieved.value = true;
    fetchData();
  },
  { immediate: true }
);

watch(
  () => masterDataLoaded.value,
  () => {
    if (masterDataLoaded.value) {
      fetchData();
    }
  }
);

watch(
  () => nlSfbSubCategory.value,
  () => {
    filterNlSfbCodes(nlSfbSubCategory.value);
    console.log("Measure, watch nlSfbSubCategory ", nlSfbSubCategory.value);
    const code = _.find(nlSfbCodes.value, { id: measure.value.nlSfbCode.id });
    if (!code) {
      measure.value.nlSfbCode.id = emptyGuid;
    }
  }
);
watch(
  () => measure.value.nlSfbCode.id,
  () => {
    console.log("Measure, watch measure.nlSfbCode.id ", measure.value.nlSfbCode.id);
    if (measure.value.nlSfbCode.id) {
      const code = _.find(nlSfbCodes.value, { id: measure.value.nlSfbCode.id });
      if (code) {
        nlSfbSubCategory.value = code.subCatId;
      }
    }
  }
);

onMounted(() => {
  loadBuildingFunctions();
  loadNlSfbCodes();
  loadCategories();
  loadKinds();
  loadConditionTypes();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  console.log("Measure, fetchData ", props.id);
  if (dataMustBeRetrieved.value && masterDataLoaded.value) {
    if (props.id && props.id !== "") {
      const response = await getDefinition(props.id);
      console.log("Measure, fetchData response ", response.data);

      if (response && !response.error) {
        measure.value = response.data;
        lastModDescr.value = measure.value.modDescr;
        if (!measure.value.nlSfbCode) {
          measure.value.nlSfbCode = { id: undefined };
        }

        measure.value.modDescr = "";
      } else {
        proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
      }
    }
    dataMustBeRetrieved.value = false;
  }
}

async function loadBuildingFunctions() {
  const response = await getEcmFunctions();
  console.log("Measure, loadBuildingFunctions ", response);
  if (response && !response.error) {
    buildingFunctions.value = _.sortBy([...response.data.ecmFunctions], "name");
    buildingFunctionsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de gebouwfuncties is mislukt! " + response.error);
  }
}

async function loadNlSfbCodes() {
  const response = await getNlSfbCodes();
  console.log("Measure, loadNlSfbCodes ", response);
  if (response && !response.error) {
    nlSfbCodes.value = _.map(_.sortBy(response.data.codes, "code"), function (item) {
      return { id: item.id, name: `${item.code}: ${item.name}`, subCatId: item.subCategory.id };
    });
    nlSfbCodes.value.unshift({ id: emptyGuid, name: "Geen" });

    nlSfbSubCategories.value = _.map(_.sortBy(response.data.subCategories, "code"), function (item) {
      return { id: item.id, name: `${item.code}: ${item.name}` };
    });
    nlSfbSubCategories.value.unshift({ id: emptyGuid, name: "Geen" });

    console.log("Measure, loadNlSfbCodes nlSfbCodes", nlSfbCodes.value);
    console.log("Measure, loadNlSfbCodes nlSfbSubCategories", nlSfbSubCategories.value);
    nlSfbCodesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de NL-SfB codes is mislukt! " + response.error);
  }
}

async function filterNlSfbCodes(id) {
  console.log("Measure, filterNlSfbCodes ", id);
  nlSfbCodesFiltered.value = _.filter(nlSfbCodes.value, function (code) {
    return code.subCatId == id;
  });
}

async function loadCategories() {
  const response = await getCategories();
  console.log("Measure, loadCategories ", response);
  if (response && !response.error) {
    categories.value = _.sortBy([...response.data.keyDescList], "description");
    categories.value.unshift({ key: null, description: "Geen" });
    categoriesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de categories is mislukt! " + response.error);
  }
}

async function loadKinds() {
  const response = await getKinds();
  console.log("Measure, loadKinds ", response);
  if (response && !response.error) {
    kinds.value = _.sortBy([...response.data.keyDescList], "description");
    kindsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de soorten is mislukt! " + response.error);
  }
}

async function loadConditionTypes() {
  const response = await getConditionTypes();
  console.log("Measure, loadConditionTypes ", response);
  if (response && !response.error) {
    conditionTypes.value = _.sortBy([...response.data.keyDescList], "description");
    conditionTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de voorwaarde types is mislukt! " + response.error);
  }
}

function checkDateValid() {
  return { on: ["input"] };
}

async function onAddVariant() {
  isSaving.value = true;
  console.log("Measure, onAddVariant start, measure ", measure.value);
  if (measure.value.variants) {
    const newVariant = { variantType: variantTypes.value[0], isNew: true };

    if (measure.value.variants.length > 0) {
      const lastType = _.last(measure.value.variants).variantType;
      if (lastType) {
        const current = _.indexOf(variantTypes.value, lastType);
        if (current < variantTypes.value.length - 1) {
          newVariant.variantType = variantTypes.value[current + 1];
        }
      }
    }

    measure.value.variants.push(newVariant);
  } else {
    measure.value.variants = [];
    measure.value.variants.push({ variantType: variantTypes.value[0] });
  }
  console.log("Measure, onAddVariant einde, measure ", measure.value);
  isSaving.value = false;
}

async function onCancel() {
  editMode.value = false;
  router.push({
    name: "Measures",
  });
}

const obs = ref(null);

async function onSave() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let response = undefined;
    if (props.addModus) {
      response = await addDefinition(measure.value);
    } else {
      console.log("Measure, onSave, measure ", measure.value);
      response = await patchDefinition(measure.value.id, measure.value);
    }
    if (response && !response.error) {
      router.push({
        name: "Measures",
      });
    } else {
      proxy.$toaster.error("Bewaren van maatregel is mislukt! " + response.error);
    }
  }
  isSaving.value = false;
}

</script>

<template>
  <v-container fluid class="app-location">
    <header class="app-welcome-message">
      <h1>
        <span v-if="addModus">Maatregel toevoegen</span><span v-else>Maatregel wijzigen</span> <router-link :to="{ name: 'Measures' }"> <v-icon>mdi-undo-variant</v-icon></router-link>
      </h1>
    </header>
    <ValidationObserver ref="obs">
      <v-form>
        <v-container fluid pa-0>
          <v-row>
            <v-col>
              <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
                <v-container class="boxed" fluid>
                  <header>Algemene gegevens</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Categorie" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          label="Categorie"
                          :items="categories"
                          item-text="description"
                          item-value="key"
                          v-model="measure.categoryKey"
                          :error-messages="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Code" rules="required|max:5" v-slot="{ errors }">
                        <v-text-field
                          hide-details="auto"
                          label="Code"
                          v-model="measure.code"
                          :error-messages="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                        <v-text-field
                          hide-details="auto"
                          label="Naam"
                          v-model="measure.name"
                          :error-messages="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        hide-details="auto"
                        label="Omschrijving"
                        v-model="measure.description"
                        persistent-placeholder
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        hide-details="auto"
                        label="Uitgangssituatie"
                        v-model="measure.baseline"
                        persistent-placeholder
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field
                        hide-details="auto"
                        label="Algemene Kostenbesparing"
                        v-model="measure.generalCostSaving"
                        persistent-placeholder
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        hide-details="auto"
                        label="Algemene Energiebesparing"
                        v-model="measure.generalEnergySaving"
                        persistent-placeholder
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="boxed" fluid>
                  <header>Type</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Bedrijfstak/Sector" rules="required" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          label="Bedrijfstak/Sector"
                          v-model="measure.buildingFunction.id"
                          :items="buildingFunctions"
                          item-text="name"
                          item-value="id"
                          :error-messages="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        hide-details="auto"
                        v-model="measure.isCertifiedMeasure"
                        label="Erkend"
                        persistent-placeholder
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        hide-details="auto"
                        v-model="measure.isApplicableAtIndependentMoment"
                        label="Uitvoerbaar op zelfstandig moment"
                        persistent-placeholder
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Soort" rules="required" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          label="Soort"
                          :items="kinds"
                          item-text="description"
                          item-value="key"
                          v-model="measure.kindKey"
                          :error-messages="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        hide-details="auto"
                        label="NL-SfB categorie"
                        :items="nlSfbSubCategories"
                        v-model="nlSfbSubCategory"
                        item-text="name"
                        item-value="id"
                        persistent-placeholder
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="NL-SfB code" v-slot="{ errors }">
                        <v-select
                          hide-details="auto"
                          label="NL-SfB code"
                          :items="nlSfbCodesFiltered"
                          item-text="name"
                          item-value="id"
                          v-model="measure.nlSfbCode.id"
                          :error-messages="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="boxed" fluid>
                  <header>Bijzondere omstandigheden</header>
                  <v-row>
                    <v-col>
                      <v-textarea
                        hide-details="auto"
                        label="Doelmatig beheer en onderhoud (DBO)"
                        v-model="measure.specialCircumstances"
                        persistent-placeholder
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Alternatieve maatregel" v-slot="{ errors }">
                        <v-text-field
                          hide-details="auto"
                          label="Altenatieve maatregel"
                          v-model="measure.alternativeName"
                          :error-messages="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        hide-details="auto"
                        label="Altenatieve maatregel omschrijving"
                        v-model="measure.alternativeDescription"
                        persistent-placeholder
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row v-if="measure.kindKey == 'EML'">
                    <v-col cols="3">
                      <ValidationProvider name="Code2023" v-slot="{ errors }">
                        <v-text-field
                          hide-details="auto"
                          label="In 2023 vervangen door code"
                          v-model="measure.code2023"
                          :error-messages="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <ValidationProvider name="Code2023Description" v-slot="{ errors }">
                        <v-text-field
                          hide-details="auto"
                          label="Reden niet in 2023 vervangen"
                          v-model="measure.code2023Description"
                          :error-messages="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="boxed" fluid>
                    <header>Kosten kerngetallen maatregelen</header>
                    <v-row>
                        <v-col cols="3">
                        </v-col>
                        <v-col>
                            <label>Kleiner dan 3000m²</label>
                        </v-col>
                        <v-col>
                            <label> Vanaf 3000m²</label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <label> Kosten per m² </label>
                        </v-col>
                        <v-col>
                            <v-text-field hide-details="auto"
                                v-model.number="measure.variableCostsTill3000"
                                persistent-placeholder
                                :outlined="allowEdit"
                                :class="{ noBorders: !allowEdit }"
                                :readonly="!allowEdit"
                                @input="measure.variableCostsTill3000 = $event === '' ? null : $event"
                                type="number">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field hide-details="auto"
                                v-model="measure.variableCostsFrom3000"
                                persistent-placeholder
                                :outlined="allowEdit"
                                :class="{ noBorders: !allowEdit }"
                                :readonly="!allowEdit"
                                type="number"
                                @input="measure.variableCostsFrom3000 = $event === '' ? null : $event">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <label> Vaste kosten </label>
                        </v-col>
                        <v-col>
                            <v-text-field hide-details="auto"
                                v-model.number="measure.fixedCostsTill3000"
                                persistent-placeholder
                                :outlined="allowEdit"
                                :class="{ noBorders: !allowEdit }"
                                :readonly="!allowEdit"
                                @input="measure.fixedCostsTill3000 = $event === '' ? null : $event"
                                type="number">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field hide-details="auto"
                                v-model="measure.fixedCostsFrom3000"
                                persistent-placeholder
                                :outlined="allowEdit"
                                :class="{ noBorders: !allowEdit }"
                                :readonly="!allowEdit"
                                type="number"
                                @input="measure.fixedCostsFrom3000 = $event === '' ? null : $event">
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3">
                            <label> Reductie aardgas of warmte </label>
                        </v-col>
                        <v-col>
                            <ValidationProvider name="Reductie aardgas of warmte" rules="decimal:4|between:0,99999|between:0,100" v-slot="{ errors }">

                                <v-text-field hide-details="auto"
                                    v-model="measure.heatOrGasReductionTill3000Percentage"
                                    persistent-placeholder
                                    :outlined="allowEdit"
                                    :class="{ noBorders: !allowEdit }"
                                    :readonly="!allowEdit"
                                    type="number"
                                    :error-messages="errors"
                                    @input="measure.heatOrGasReductionPercentageTill3000= $event === '' ? null : $event">
                                    <template slot="append">
                                        %
                                    </template>
                                </v-text-field>
                            </ValidationProvider>

                        </v-col>
                        <v-col>
                            <ValidationProvider name="Reductie aardgas of warmte" rules="decimal:4|between:0,99999|between:0,100" v-slot="{ errors }">
                                <v-text-field hide-details="auto"
                                    v-model="measure.heatOrGasReductionPercentageFrom3000"
                                    persistent-placeholder
                                    :outlined="allowEdit"
                                    :class="{ noBorders: !allowEdit }"
                                    :readonly="!allowEdit"
                                    type="number"
                                    :error-messages="errors"
                                    @input="measure.heatOrGasReductionPercentageFrom3000 = $event === '' ? null : $event">
                                    <template slot="append">
                                        %
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3">
                            <label> Reductie elektra </label>
                        </v-col>
                        <v-col>
                            <ValidationProvider name="Reductie" rules="decimal:4|between:0,99999|between:0,100" v-slot="{ errors }">
                                <v-text-field hide-details="auto"
                                    v-model="measure.electricReductionPercentageTill3000"
                                    persistent-placeholder
                                    :outlined="allowEdit"
                                    :class="{ noBorders: !allowEdit }"
                                    :readonly="!allowEdit"
                                    type="number"
                                    :error-messages="errors"
                                    @input="measure.electricReductionPercentageTill3000 = $event === '' ? null : $event">
                                    <template slot="append">
                                        %
                                    </template>
                                </v-text-field>
                            </ValidationProvider>

                        </v-col>
                        <v-col>
                            <ValidationProvider name="Reductie elektra" rules="decimal:4|between:0,99999|between:0,100" v-slot="{ errors }">
                                <v-text-field hide-details="auto"
                                    v-model="measure.electricReductionPercentageFrom3000"
                                    persistent-placeholder
                                    :outlined="allowEdit"
                                    :class="{ noBorders: !allowEdit }"
                                    :readonly="!allowEdit"
                                    type="number"
                                    :error-messages="errors"
                                    @input="measure.electricReductionPercentageFrom3000 = $event === '' ? null : $event">
                                    <template slot="append">
                                        %
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </v-container>
              </masonry>

              <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
                <v-container class="boxed" fluid :class="{ noBorders: false }">
                  <header>Geldigheid</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Geldig vanaf" ref="validFrom" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                        <date-input
                          v-model="measure.validFrom"
                          label="Geldig vanaf"
                          :errors="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                          rules="required"
                        ></date-input>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${measure.validFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                        <date-input
                          v-model="measure.validTill"
                          label="Geldig tot"
                          :errors="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></date-input>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="boxed" fluid :class="{ noBorders: false }">
                  <header>Aanpassing</header>
                  <v-row>
                    <v-col>
                      <ValidationProvider name="Aanpassings omschrijving" rules="required" v-slot="{ errors }">
                        <v-text-field
                          hide-details="auto"
                          label="Omschrijving"
                          v-model="measure.modDescr"
                          :error-messages="errors"
                          persistent-placeholder
                          :outlined="allowEdit"
                          :class="{ noBorders: !allowEdit }"
                          :readonly="!allowEdit"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field hide-details="auto" label="Laatste omschrijving" v-model="lastModDescr" readonly class="noBorders"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field hide-details="auto" label="Door" v-model="measure.modBy" readonly class="noBorders"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field hide-details="auto" label="Datum" v-model="modDateFormatted" readonly class="noBorders"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </masonry>
              <div>
                <v-container class="boxed withDivider" fluid>
                  <header>Varianten / voorwaarden</header>

                  <v-row v-for="(variant, index) in measure.variants" :key="index">
                    <v-col>
                      <template v-if="measure.kindKey != 'EML'">
                        <v-row>
                          <v-col cols="3">
                            <v-select
                              hide-details="auto"
                              :items="variantTypes"
                              label="Variant code"
                              v-model="variant.variantType"
                              persistent-placeholder
                              :outlined="allowEdit"
                              :class="{ noBorders: !allowEdit }"
                              :readonly="!allowEdit"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3">
                            <v-select
                              hide-details="auto"
                              label="Soort voorwaarde"
                              :items="conditionTypes"
                              item-text="description"
                              item-value="key"
                              v-model="variant.conditionTypeKey"
                              persistent-placeholder
                              :outlined="allowEdit"
                              :class="{ noBorders: !allowEdit }"
                              :readonly="!allowEdit"
                            ></v-select>
                          </v-col>
                          <v-col cols="9">
                            <v-textarea
                              hide-details="auto"
                              auto-grow
                              rows="1"
                              label="Voorwaarde"
                              v-model="variant.condition"
                              persistent-placeholder
                              :outlined="allowEdit"
                              :class="{ noBorders: !allowEdit }"
                              :readonly="!allowEdit"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3">
                            <ValidationProvider name="RVO uitzonderings ID" rules="decimal:2|between:0,99999" v-slot="{ errors }">
                              <v-text-field
                                v-model="variant.rvoId"
                                hide-details="auto"
                                type="number"
                                step="1"
                                label="RVO uitzonderings ID"
                                persistent-placeholder
                                :error-messages="errors"
                                :outlined="allowEdit"
                                :class="{ noBorders: !allowEdit }"
                                :readonly="!allowEdit"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row>
                          <v-col cols="6">
                            <v-select
                              hide-details="auto"
                              :items="variantTypes"
                              label="Variant"
                              v-model="variant.variantType"
                              persistent-placeholder
                              :outlined="allowEdit && variant.isNew"
                              :class="{ noBorders: !allowEdit }"
                              :readonly="!allowEdit || !variant.isNew"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <ValidationProvider name="Uitgangssituatie op basis van een referentietechniek" v-slot="{ errors }">
                              <v-textarea
                                hide-details="auto"
                                auto-grow
                                rows="1"
                                label="Uitgangssituatie op basis van een referentietechniek"
                                v-model="variant.baseline"
                                :error-messages="errors"
                                persistent-placeholder
                                :outlined="allowEdit"
                                :class="{ noBorders: !allowEdit }"
                                :readonly="!allowEdit"
                              ></v-textarea>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="6">
                            <ValidationProvider name="Mogelijke technieken ten opzichte van uitgangssituatie" v-slot="{ errors }">
                              <v-textarea
                                hide-details="auto"
                                auto-grow
                                rows="1"
                                label="Mogelijke technieken ten opzichte van uitgangssituatie"
                                v-model="variant.techniques"
                                :error-messages="errors"
                                persistent-placeholder
                                :outlined="allowEdit"
                                :class="{ noBorders: !allowEdit }"
                                :readonly="!allowEdit"
                              ></v-textarea>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="pt-3">
                    <v-col cols="auto" v-show="editMode">
                      <v-btn class="primary" v-on:click="onAddVariant()" :disabled="disableSave">
                        <v-icon dark left>mdi-plus</v-icon>
                        Variant toevoegen
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid>
          <v-row>
            <v-col cols="auto" class="form-group" v-show="editMode">
              <v-btn class="primary" v-on:click="onSave()" :disabled="disableSave">
                <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                Opslaan
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group" v-show="editMode">
              <v-btn v-on:click="onCancel()">
                <v-icon dark left>mdi-cancel</v-icon>
                Annuleren
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>
