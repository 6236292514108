var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-location", attrs: { fluid: "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        _c(
          "h1",
          [
            _vm.addModus
              ? _c("span", [_vm._v("Maatregel toevoegen")])
              : _c("span", [_vm._v("Maatregel wijzigen")]),
            _vm._v(" "),
            _c(
              "router-link",
              { attrs: { to: { name: "Measures" } } },
              [_c("v-icon", [_vm._v("mdi-undo-variant")])],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        _setup.ValidationObserver,
        { ref: "obs" },
        [
          _c(
            "v-form",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pa-0": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "masonry",
                            {
                              attrs: {
                                cols: { default: 2, 1264: 1 },
                                gutter: 12,
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [_vm._v("Algemene gegevens")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: { name: "Categorie" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Categorie",
                                                        items:
                                                          _setup.categories,
                                                        "item-text":
                                                          "description",
                                                        "item-value": "key",
                                                        "error-messages":
                                                          errors,
                                                        "persistent-placeholder":
                                                          "",
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.measure
                                                            .categoryKey,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.measure,
                                                            "categoryKey",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "measure.categoryKey",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Code",
                                              rules: "required|max:5",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Code",
                                                        "error-messages":
                                                          errors,
                                                        "persistent-placeholder":
                                                          "",
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.measure.code,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.measure,
                                                            "code",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "measure.code",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Name",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Naam",
                                                        "error-messages":
                                                          errors,
                                                        "persistent-placeholder":
                                                          "",
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.measure.name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.measure,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "measure.name",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-textarea", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Omschrijving",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value: _setup.measure.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression: "measure.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-textarea", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Uitgangssituatie",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value: _setup.measure.baseline,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "baseline",
                                                  $$v
                                                )
                                              },
                                              expression: "measure.baseline",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Algemene Kostenbesparing",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value:
                                                _setup.measure
                                                  .generalCostSaving,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "generalCostSaving",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "measure.generalCostSaving",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label:
                                                "Algemene Energiebesparing",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value:
                                                _setup.measure
                                                  .generalEnergySaving,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "generalEnergySaving",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "measure.generalEnergySaving",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [_vm._v("Type")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Bedrijfstak/Sector",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label:
                                                          "Bedrijfstak/Sector",
                                                        items:
                                                          _setup.buildingFunctions,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        "error-messages":
                                                          errors,
                                                        "persistent-placeholder":
                                                          "",
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.measure
                                                            .buildingFunction
                                                            .id,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.measure
                                                              .buildingFunction,
                                                            "id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "measure.buildingFunction.id",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-switch", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Erkend",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value:
                                                _setup.measure
                                                  .isCertifiedMeasure,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "isCertifiedMeasure",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "measure.isCertifiedMeasure",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-switch", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label:
                                                "Uitvoerbaar op zelfstandig moment",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value:
                                                _setup.measure
                                                  .isApplicableAtIndependentMoment,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "isApplicableAtIndependentMoment",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "measure.isApplicableAtIndependentMoment",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Soort",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Soort",
                                                        items: _setup.kinds,
                                                        "item-text":
                                                          "description",
                                                        "item-value": "key",
                                                        "error-messages":
                                                          errors,
                                                        "persistent-placeholder":
                                                          "",
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.measure
                                                            .kindKey,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.measure,
                                                            "kindKey",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "measure.kindKey",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-select", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "NL-SfB categorie",
                                              items: _setup.nlSfbSubCategories,
                                              "item-text": "name",
                                              "item-value": "id",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value: _setup.nlSfbSubCategory,
                                              callback: function ($$v) {
                                                _setup.nlSfbSubCategory = $$v
                                              },
                                              expression: "nlSfbSubCategory",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: { name: "NL-SfB code" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "NL-SfB code",
                                                        items:
                                                          _setup.nlSfbCodesFiltered,
                                                        "item-text": "name",
                                                        "item-value": "id",
                                                        "error-messages":
                                                          errors,
                                                        "persistent-placeholder":
                                                          "",
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.measure
                                                            .nlSfbCode.id,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.measure
                                                              .nlSfbCode,
                                                            "id",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "measure.nlSfbCode.id",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [
                                    _vm._v("Bijzondere omstandigheden"),
                                  ]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-textarea", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label:
                                                "Doelmatig beheer en onderhoud (DBO)",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value:
                                                _setup.measure
                                                  .specialCircumstances,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "specialCircumstances",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "measure.specialCircumstances",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Alternatieve maatregel",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label:
                                                          "Altenatieve maatregel",
                                                        "error-messages":
                                                          errors,
                                                        "persistent-placeholder":
                                                          "",
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.measure
                                                            .alternativeName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.measure,
                                                            "alternativeName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "measure.alternativeName",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-textarea", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label:
                                                "Altenatieve maatregel omschrijving",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value:
                                                _setup.measure
                                                  .alternativeDescription,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "alternativeDescription",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "measure.alternativeDescription",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _setup.measure.kindKey == "EML"
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c(_setup.ValidationProvider, {
                                                attrs: { name: "Code2023" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c("v-text-field", {
                                                            class: {
                                                              noBorders:
                                                                !_setup.allowEdit,
                                                            },
                                                            attrs: {
                                                              "hide-details":
                                                                "auto",
                                                              label:
                                                                "In 2023 vervangen door code",
                                                              "error-messages":
                                                                errors,
                                                              "persistent-placeholder":
                                                                "",
                                                              outlined:
                                                                _setup.allowEdit,
                                                              readonly:
                                                                !_setup.allowEdit,
                                                            },
                                                            model: {
                                                              value:
                                                                _setup.measure
                                                                  .code2023,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _setup.measure,
                                                                    "code2023",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "measure.code2023",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1926376364
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c(_setup.ValidationProvider, {
                                                attrs: {
                                                  name: "Code2023Description",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c("v-text-field", {
                                                            class: {
                                                              noBorders:
                                                                !_setup.allowEdit,
                                                            },
                                                            attrs: {
                                                              "hide-details":
                                                                "auto",
                                                              label:
                                                                "Reden niet in 2023 vervangen",
                                                              "error-messages":
                                                                errors,
                                                              "persistent-placeholder":
                                                                "",
                                                              outlined:
                                                                _setup.allowEdit,
                                                              readonly:
                                                                !_setup.allowEdit,
                                                            },
                                                            model: {
                                                              value:
                                                                _setup.measure
                                                                  .code2023Description,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _setup.measure,
                                                                    "code2023Description",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "measure.code2023Description",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3009168831
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [
                                    _vm._v("Kosten kerngetallen maatregelen"),
                                  ]),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "3" } }),
                                      _c("v-col", [
                                        _c("label", [
                                          _vm._v("Kleiner dan 3000m²"),
                                        ]),
                                      ]),
                                      _c("v-col", [
                                        _c("label", [_vm._v(" Vanaf 3000m²")]),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "3" } }, [
                                        _c("label", [
                                          _vm._v(" Kosten per m² "),
                                        ]),
                                      ]),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                              type: "number",
                                            },
                                            on: {
                                              input: function ($event) {
                                                _setup.measure.variableCostsTill3000 =
                                                  $event === "" ? null : $event
                                              },
                                            },
                                            model: {
                                              value:
                                                _setup.measure
                                                  .variableCostsTill3000,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "variableCostsTill3000",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "measure.variableCostsTill3000",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                              type: "number",
                                            },
                                            on: {
                                              input: function ($event) {
                                                _setup.measure.variableCostsFrom3000 =
                                                  $event === "" ? null : $event
                                              },
                                            },
                                            model: {
                                              value:
                                                _setup.measure
                                                  .variableCostsFrom3000,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "variableCostsFrom3000",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "measure.variableCostsFrom3000",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "3" } }, [
                                        _c("label", [_vm._v(" Vaste kosten ")]),
                                      ]),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                              type: "number",
                                            },
                                            on: {
                                              input: function ($event) {
                                                _setup.measure.fixedCostsTill3000 =
                                                  $event === "" ? null : $event
                                              },
                                            },
                                            model: {
                                              value:
                                                _setup.measure
                                                  .fixedCostsTill3000,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "fixedCostsTill3000",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "measure.fixedCostsTill3000",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                              type: "number",
                                            },
                                            on: {
                                              input: function ($event) {
                                                _setup.measure.fixedCostsFrom3000 =
                                                  $event === "" ? null : $event
                                              },
                                            },
                                            model: {
                                              value:
                                                _setup.measure
                                                  .fixedCostsFrom3000,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "fixedCostsFrom3000",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "measure.fixedCostsFrom3000",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "3" } }, [
                                        _c("label", [
                                          _vm._v(
                                            " Reductie aardgas of warmte "
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Reductie aardgas of warmte",
                                              rules:
                                                "decimal:4|between:0,99999|between:0,100",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      {
                                                        class: {
                                                          noBorders:
                                                            !_setup.allowEdit,
                                                        },
                                                        attrs: {
                                                          "hide-details":
                                                            "auto",
                                                          "persistent-placeholder":
                                                            "",
                                                          outlined:
                                                            _setup.allowEdit,
                                                          readonly:
                                                            !_setup.allowEdit,
                                                          type: "number",
                                                          "error-messages":
                                                            errors,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _setup.measure.heatOrGasReductionPercentageTill3000 =
                                                              $event === ""
                                                                ? null
                                                                : $event
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _setup.measure
                                                              .heatOrGasReductionTill3000Percentage,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _setup.measure,
                                                              "heatOrGasReductionTill3000Percentage",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "measure.heatOrGasReductionTill3000Percentage",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "append" },
                                                          [_vm._v(" % ")]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Reductie aardgas of warmte",
                                              rules:
                                                "decimal:4|between:0,99999|between:0,100",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      {
                                                        class: {
                                                          noBorders:
                                                            !_setup.allowEdit,
                                                        },
                                                        attrs: {
                                                          "hide-details":
                                                            "auto",
                                                          "persistent-placeholder":
                                                            "",
                                                          outlined:
                                                            _setup.allowEdit,
                                                          readonly:
                                                            !_setup.allowEdit,
                                                          type: "number",
                                                          "error-messages":
                                                            errors,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _setup.measure.heatOrGasReductionPercentageFrom3000 =
                                                              $event === ""
                                                                ? null
                                                                : $event
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _setup.measure
                                                              .heatOrGasReductionPercentageFrom3000,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _setup.measure,
                                                              "heatOrGasReductionPercentageFrom3000",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "measure.heatOrGasReductionPercentageFrom3000",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "append" },
                                                          [_vm._v(" % ")]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "3" } }, [
                                        _c("label", [
                                          _vm._v(" Reductie elektra "),
                                        ]),
                                      ]),
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Reductie",
                                              rules:
                                                "decimal:4|between:0,99999|between:0,100",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      {
                                                        class: {
                                                          noBorders:
                                                            !_setup.allowEdit,
                                                        },
                                                        attrs: {
                                                          "hide-details":
                                                            "auto",
                                                          "persistent-placeholder":
                                                            "",
                                                          outlined:
                                                            _setup.allowEdit,
                                                          readonly:
                                                            !_setup.allowEdit,
                                                          type: "number",
                                                          "error-messages":
                                                            errors,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _setup.measure.electricReductionPercentageTill3000 =
                                                              $event === ""
                                                                ? null
                                                                : $event
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _setup.measure
                                                              .electricReductionPercentageTill3000,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _setup.measure,
                                                              "electricReductionPercentageTill3000",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "measure.electricReductionPercentageTill3000",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "append" },
                                                          [_vm._v(" % ")]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Reductie elektra",
                                              rules:
                                                "decimal:4|between:0,99999|between:0,100",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      {
                                                        class: {
                                                          noBorders:
                                                            !_setup.allowEdit,
                                                        },
                                                        attrs: {
                                                          "hide-details":
                                                            "auto",
                                                          "persistent-placeholder":
                                                            "",
                                                          outlined:
                                                            _setup.allowEdit,
                                                          readonly:
                                                            !_setup.allowEdit,
                                                          type: "number",
                                                          "error-messages":
                                                            errors,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _setup.measure.electricReductionPercentageFrom3000 =
                                                              $event === ""
                                                                ? null
                                                                : $event
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _setup.measure
                                                              .electricReductionPercentageFrom3000,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _setup.measure,
                                                              "electricReductionPercentageFrom3000",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "measure.electricReductionPercentageFrom3000",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "append" },
                                                          [_vm._v(" % ")]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "masonry",
                            {
                              attrs: {
                                cols: { default: 2, 1264: 1 },
                                gutter: 12,
                              },
                            },
                            [
                              _c(
                                "v-container",
                                {
                                  staticClass: "boxed",
                                  class: { noBorders: false },
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c("header", [_vm._v("Geldigheid")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            ref: "validFrom",
                                            attrs: {
                                              name: "Geldig vanaf",
                                              rules: "date_between|required",
                                              mode: _setup.checkDateValid,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(_setup.DateInput, {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        label: "Geldig vanaf",
                                                        errors: errors,
                                                        "persistent-placeholder":
                                                          "",
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                        rules: "required",
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.measure
                                                            .validFrom,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.measure,
                                                            "validFrom",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "measure.validFrom",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Geldig tot",
                                              rules: `date_between|date_after:${_setup.measure.validFrom}`,
                                              mode: _setup.checkDateValid,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(_setup.DateInput, {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        label: "Geldig tot",
                                                        errors: errors,
                                                        "persistent-placeholder":
                                                          "",
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.measure
                                                            .validTill,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.measure,
                                                            "validTill",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "measure.validTill",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                {
                                  staticClass: "boxed",
                                  class: { noBorders: false },
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c("header", [_vm._v("Aanpassing")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Aanpassings omschrijving",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Omschrijving",
                                                        "error-messages":
                                                          errors,
                                                        "persistent-placeholder":
                                                          "",
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.measure
                                                            .modDescr,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.measure,
                                                            "modDescr",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "measure.modDescr",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Laatste omschrijving",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.lastModDescr,
                                              callback: function ($$v) {
                                                _setup.lastModDescr = $$v
                                              },
                                              expression: "lastModDescr",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Door",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.measure.modBy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.measure,
                                                  "modBy",
                                                  $$v
                                                )
                                              },
                                              expression: "measure.modBy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Datum",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.modDateFormatted,
                                              callback: function ($$v) {
                                                _setup.modDateFormatted = $$v
                                              },
                                              expression: "modDateFormatted",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "v-container",
                                {
                                  staticClass: "boxed withDivider",
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c("header", [
                                    _vm._v("Varianten / voorwaarden"),
                                  ]),
                                  _vm._l(
                                    _setup.measure.variants,
                                    function (variant, index) {
                                      return _c(
                                        "v-row",
                                        { key: index },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _setup.measure.kindKey != "EML"
                                                ? [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              class: {
                                                                noBorders:
                                                                  !_setup.allowEdit,
                                                              },
                                                              attrs: {
                                                                "hide-details":
                                                                  "auto",
                                                                items:
                                                                  _setup.variantTypes,
                                                                label:
                                                                  "Variant code",
                                                                "persistent-placeholder":
                                                                  "",
                                                                outlined:
                                                                  _setup.allowEdit,
                                                                readonly:
                                                                  !_setup.allowEdit,
                                                              },
                                                              model: {
                                                                value:
                                                                  variant.variantType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      variant,
                                                                      "variantType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "variant.variantType",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              class: {
                                                                noBorders:
                                                                  !_setup.allowEdit,
                                                              },
                                                              attrs: {
                                                                "hide-details":
                                                                  "auto",
                                                                label:
                                                                  "Soort voorwaarde",
                                                                items:
                                                                  _setup.conditionTypes,
                                                                "item-text":
                                                                  "description",
                                                                "item-value":
                                                                  "key",
                                                                "persistent-placeholder":
                                                                  "",
                                                                outlined:
                                                                  _setup.allowEdit,
                                                                readonly:
                                                                  !_setup.allowEdit,
                                                              },
                                                              model: {
                                                                value:
                                                                  variant.conditionTypeKey,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      variant,
                                                                      "conditionTypeKey",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "variant.conditionTypeKey",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "9",
                                                            },
                                                          },
                                                          [
                                                            _c("v-textarea", {
                                                              class: {
                                                                noBorders:
                                                                  !_setup.allowEdit,
                                                              },
                                                              attrs: {
                                                                "hide-details":
                                                                  "auto",
                                                                "auto-grow": "",
                                                                rows: "1",
                                                                label:
                                                                  "Voorwaarde",
                                                                "persistent-placeholder":
                                                                  "",
                                                                outlined:
                                                                  _setup.allowEdit,
                                                                readonly:
                                                                  !_setup.allowEdit,
                                                              },
                                                              model: {
                                                                value:
                                                                  variant.condition,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      variant,
                                                                      "condition",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "variant.condition",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _setup.ValidationProvider,
                                                              {
                                                                attrs: {
                                                                  name: "RVO uitzonderings ID",
                                                                  rules:
                                                                    "decimal:2|between:0,99999",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                class:
                                                                                  {
                                                                                    noBorders:
                                                                                      !_setup.allowEdit,
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    "hide-details":
                                                                                      "auto",
                                                                                    type: "number",
                                                                                    step: "1",
                                                                                    label:
                                                                                      "RVO uitzonderings ID",
                                                                                    "persistent-placeholder":
                                                                                      "",
                                                                                    "error-messages":
                                                                                      errors,
                                                                                    outlined:
                                                                                      _setup.allowEdit,
                                                                                    readonly:
                                                                                      !_setup.allowEdit,
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      variant.rvoId,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          variant,
                                                                                          "rvoId",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "variant.rvoId",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "6",
                                                            },
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              class: {
                                                                noBorders:
                                                                  !_setup.allowEdit,
                                                              },
                                                              attrs: {
                                                                "hide-details":
                                                                  "auto",
                                                                items:
                                                                  _setup.variantTypes,
                                                                label:
                                                                  "Variant",
                                                                "persistent-placeholder":
                                                                  "",
                                                                outlined:
                                                                  _setup.allowEdit &&
                                                                  variant.isNew,
                                                                readonly:
                                                                  !_setup.allowEdit ||
                                                                  !variant.isNew,
                                                              },
                                                              model: {
                                                                value:
                                                                  variant.variantType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      variant,
                                                                      "variantType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "variant.variantType",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _setup.ValidationProvider,
                                                              {
                                                                attrs: {
                                                                  name: "Uitgangssituatie op basis van een referentietechniek",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-textarea",
                                                                              {
                                                                                class:
                                                                                  {
                                                                                    noBorders:
                                                                                      !_setup.allowEdit,
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    "hide-details":
                                                                                      "auto",
                                                                                    "auto-grow":
                                                                                      "",
                                                                                    rows: "1",
                                                                                    label:
                                                                                      "Uitgangssituatie op basis van een referentietechniek",
                                                                                    "error-messages":
                                                                                      errors,
                                                                                    "persistent-placeholder":
                                                                                      "",
                                                                                    outlined:
                                                                                      _setup.allowEdit,
                                                                                    readonly:
                                                                                      !_setup.allowEdit,
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      variant.baseline,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          variant,
                                                                                          "baseline",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "variant.baseline",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _setup.ValidationProvider,
                                                              {
                                                                attrs: {
                                                                  name: "Mogelijke technieken ten opzichte van uitgangssituatie",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function ({
                                                                          errors,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "v-textarea",
                                                                              {
                                                                                class:
                                                                                  {
                                                                                    noBorders:
                                                                                      !_setup.allowEdit,
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    "hide-details":
                                                                                      "auto",
                                                                                    "auto-grow":
                                                                                      "",
                                                                                    rows: "1",
                                                                                    label:
                                                                                      "Mogelijke technieken ten opzichte van uitgangssituatie",
                                                                                    "error-messages":
                                                                                      errors,
                                                                                    "persistent-placeholder":
                                                                                      "",
                                                                                    outlined:
                                                                                      _setup.allowEdit,
                                                                                    readonly:
                                                                                      !_setup.allowEdit,
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      variant.techniques,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          variant,
                                                                                          "techniques",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "variant.techniques",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "pt-3" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _setup.editMode,
                                              expression: "editMode",
                                            },
                                          ],
                                          attrs: { cols: "auto" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "primary",
                                              attrs: {
                                                disabled: _setup.disableSave,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _setup.onAddVariant()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { dark: "", left: "" },
                                                },
                                                [_vm._v("mdi-plus")]
                                              ),
                                              _vm._v(" Variant toevoegen "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _setup.editMode,
                              expression: "editMode",
                            },
                          ],
                          staticClass: "form-group",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { disabled: _setup.disableSave },
                              on: {
                                click: function ($event) {
                                  return _setup.onSave()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-checkbox-marked-circle"),
                              ]),
                              _vm._v(" Opslaan "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _setup.editMode,
                              expression: "editMode",
                            },
                          ],
                          staticClass: "form-group",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _setup.onCancel()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-cancel"),
                              ]),
                              _vm._v(" Annuleren "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }